.dot-icon {
  width: 10px;
  height: 10px;
  background: linear-gradient(60deg, #9e1f63 21%, #d91c5c 53%, #ed4663 99%);
  border-radius: 50%;
  position: absolute;
  left: 2%;
  top: 0;
  bottom: 0;
  margin: auto;
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.dot-icon-home {
  width: 10px;
  height: 10px;
  background: linear-gradient(60deg, #9e1f63 21%, #d91c5c 53%, #ed4663 99%);
  border-radius: 50%;
  @media screen and (max-width: 1000px) {
    display: none;
  }
}
