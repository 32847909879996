.chips-title {
  color: #333;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.blue-bg-chips {
  background: rgba(0, 113, 188, 0.05);
}
