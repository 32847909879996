.primary-btn {
  border-radius: 40px;
  background: linear-gradient(53deg, #9e1f63 22.38%, #d91c5c 45.85%, #ed4663 80.16%);
  box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.05);
  padding: 18px 48px;
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  position: relative;
  &:hover,
  :focus,
  :active {
    .arrow-icon {
      display: block;
    }
  }
  .arrow-icon {
    position: absolute;
    display: none;
    right: 16px;
    top: 10.75px;
  }
  @media screen and (max-width: 900px) {
    line-height: 14px;
    padding: 11px 24px;
    border-radius: 40px;
  }
}
.trans-btn {
  border-radius: 40px;
  border: 2px solid #fff;
  background: transparent;
  padding: 16px 48px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  @media screen and (max-width: 900px) {
    line-height: 14px;
    padding: 10px 24px;
    border-radius: 40px;
    border: 1px solid #fff;
  }
}
