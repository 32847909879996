.landing-page {
  position: relative;
  background: linear-gradient(
    180deg,
    #1e1b38 0%,
    rgba(42, 71, 115, 0.99) 22.51%,
    #0071bc 50.02%,
    #2e8bc9 71.3%,
    #89c0e4 100%
  );
  .hero-section {
    height: 700px;
    width: 100%;
    background-image: url('../../assets/images/hero.png');
    background-size: 100% 100%;
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    background-color: #191335;
    .content-box {
      padding-top: 196px;
      padding-left: 100px;
      max-width: 635px;
      .title {
        color: #fff;
        font-family: Montserrat;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px;
        text-transform: capitalize;
        .next-gen {
          color: #fdc60b;
        }
      }
      .sub-content {
        margin: 32px 0px 0px 24px;
        max-width: 75%;
        display: flex;
        .sub-title {
          color: #fff;
          font-family: Montserrat;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          @media screen and (max-width: 600px) {
            font-size: 12px;
          }
        }
        .vertical-divider {
          width: 2px;
          height: 152px;
          margin-right: 24px;
          background: linear-gradient(180deg, #fff 1.24%, #1282c3 99.75%, #408ac6 106.12%);
        }
      }
    }
  }
  .join-section {
    background: linear-gradient(180deg, #191335 0%, #00508b 55.14%);
    padding: 70px 100px;
    .join-para {
      max-width: 1032px;
      margin: 0 auto;
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px;
    }
    .ourview-box {
      margin-top: 70px;
      .heading {
        color: #fff;
        font-family: Montserrat;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px;
        @media screen and (max-width: 1000px) {
          font-size: 32px;
        }
        @media screen and (max-width: 600px) {
          font-size: 28px;
        }
      }
      .content {
        color: #fff;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        @media screen and (max-width: 600px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
      .sub-heading {
        margin-top: 12px;
        color: #fff;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        @media screen and (max-width: 600px) {
          line-height: 24px;
          font-size: 16px;
        }
      }
    }
  }
  .work-section {
    padding: 0 100px;
    min-height: 506px;
    background-image: url('../../assets/images/expansion_depth_potential 1.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; /* Center horizontally and vertically */
    height: 900px; /* Adjust the desired height */
    width: 100%; /* Ensure the container takes the full width */
    padding-top: 7.5%;
    .heading {
      padding-top: 70px;
      color: #fff;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 60px;
      @media screen and (max-width: 1000px) {
        font-size: 32px;
      }
    }
    .content {
      max-width: 676px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
  .about-section {
    padding: 57px 100px 50px 100px;
    min-height: 417px;
    background: linear-gradient(180deg, #005a98 8.63%, rgba(0, 80, 139, 0) 100%);
    .heading {
      color: #fff;
      font-family: Montserrat;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 60px; /* 125% */
      @media screen and (max-width: 1000px) {
        font-size: 32px;
      }
    }
    .name {
      color: #fff;
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @media screen and (max-width: 1000px) {
        margin-top: 10px;
        font-size: 22px;
      }
    }
  }

  .weaim-section {
    padding: 0 100px;
    .heading {
      padding-top: 60px;
      color: #fff;
      font-family: Montserrat;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 60px;
    }
    .content {
      color: #fff;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 42px;
    }
    ul {
      padding: 0;
    }
    li {
      svg {
        margin-right: 16px;
        margin-top: 10px;
      }
      max-width: 690px;
      list-style: none;
      color: #fff;
      margin-bottom: 20px;
    }
  }
  .footer-section {
    background: linear-gradient(110deg, #60a8d8 0%, rgba(96, 168, 216, 0) 89.53%);
    .footer-logo {
      margin-top: 32px;
      padding: 0 100px;
    }
    .landing-footer {
      position: relative;
    }
    .landing-footer {
      margin: 0 100px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      .purpose-text {
        color: #fff;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 33px;
        max-width: 424px;
        margin-bottom: 2.5rem;
        @media screen and (max-width: 1000px) {
          max-width: 100%;
        }
        @media screen and (max-width: 600px) {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 1rem;
        }
      }
      .footer-link-heading {
        color: #fff;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        text-transform: uppercase;
        @media screen and (max-width: 600px) {
          margin-top: 20px;
          font-size: 16px;
        }
      }
      .footer-links {
        li {
          margin-top: 12px;
          cursor: pointer;
          a {
            color: #fff;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            @media screen and (max-width: 600px) {
              font-size: 14px;
            }
          }
        }
      }
      .email {
        color: #fff;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
      }
    }
  }

  .landing-footer-img {
    mix-blend-mode: luminosity;
    @media screen and (max-width: 1000px) {
      opacity: 0.5;
    }
    @media screen and (max-width: 600px) {
      opacity: 1;
      width: 150px;
      margin-bottom: 72px;
    }
  }
  .copyright {
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
  .icon-gap {
    @media screen and (max-width: 600px) {
      gap: 2.5em !important;
    }
  }
  .social-links {
    cursor: pointer;
  }
  .underline {
    width: 80px;
    height: 2px;
    background: #f2f2f2;
    @media screen and (max-width: 600px) {
      margin-top: 10px;
    }
  }
  .carousel-slider {
    padding-bottom: 18px !important;
  }
  .yellow-text {
    color: #fdc60b;
  }
  .about-para {
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 2500px) {
    .work-section {
      padding-top: 10%;
    }
  }
  @media screen and (max-width: 2000px) {
    .work-section {
      padding-top: 11%;
    }
  }
  @media screen and (max-width: 1800px) {
    .work-section {
      height: auto; /* Adjust the desired height */
      padding-top: 0%;
    }
  }
  @media screen and (max-width: 600px) {
    .join-section,
    .ourview-section,
    .work-section,
    .weaim-section,
    .landing-footer,
    .about-section {
      padding-left: 36px;
      padding-right: 36px;
    }
    .landing-footer {
      margin: 0 !important;
    }
    .footer-section {
      padding-top: 1px;
      .footer-logo {
        margin-top: 20px;
        padding: 0 36px;
      }
    }

    .hero-section {
      height: 400px;
      background-image: none;
      .content-box {
        padding-top: 53px;
        padding-left: 36px;
        padding-right: 36px;
        .title {
          font-size: 28px;
          line-height: 34px;
        }
        .sub-content {
          margin-left: 0;
          max-width: 85%;
        }
      }
    }
    .join-section {
      .join-para {
        font-size: 14px;
        line-height: 21px;
      }
      .heading {
        font-size: 28px;
        line-height: 34px;
      }
      .content,
      .card-content {
        font-size: 14px;
        font-weight: 500;
      }
    }
    .work-section {
      background-image: url('../../assets/images/expansion_depth_potential 2.svg');
      height: 542px;
      .heading {
        max-width: 293px;
        padding-top: 50px;
        font-size: 28px;
        line-height: 34px;
        padding-top: 30px;
      }
    }
    .content,
    .card-content {
      line-height: 17px;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .ourview-section,
  .about-section {
    .heading {
      font-size: 28px;
      line-height: 34px;
    }
    .content {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .join-section,
  .ourview-section,
  .work-section,
  .weaim-section,
  .landing-footer,
  .about-section {
    padding-left: 36px;
    padding-right: 36px;
  }
  .landing-footer {
    margin: 0 !important;
  }
  .footer-section {
    padding-top: 1px;
    .footer-logo {
      padding: 0 36px;
    }
  }
  .hero-section {
    height: 420px;
    .content-box {
      padding-top: 96px;
      padding-left: 36px;
      .title {
        font-size: 28px;
        line-height: 34px;
        .next-gen {
          color: #fdc60b;
        }
      }
      .sub-content {
        .sub-title {
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          max-width: 250px;
        }
      }
    }
  }
  .work-section {
    background-image: url('../../assets/images/expansion_depth_potential 1.svg');
  }
}
