.work-card-wrap {
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  .card-title {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 35px;
    text-align: center;
    .left {
      color: #fdc60b;
    }
    .right {
      color: #fff;
    }
    @media screen and (max-width: 600px) {
      margin-top: 45px;
      font-size: 18px;
      margin-bottom: 33px !important;
    }
    @media screen and (max-width: 350px) {
      margin-top: 20px;
    }
  }
  .card-divider {
    margin: 12px auto;
  }
  .card-content {
    margin: 0 auto;
    max-width: 1087px;
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    @media screen and (max-width: 350px) {
      font-size: 10px !important;
    }
  }
  @media screen and (max-width: 600px) {
    margin-top: 0px;
  }
}
